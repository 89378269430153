/* tslint:disable */
/* eslint-disable */
/**
 * IT-Doc
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCommentToTaskRequestDto
 */
export interface AddCommentToTaskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddCommentToTaskRequestDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Beneficiary = {
    Ooo: 'OOO',
    Ip: 'IP'
} as const;

export type Beneficiary = typeof Beneficiary[keyof typeof Beneficiary];


/**
 * 
 * @export
 * @interface ClientResponseDto
 */
export interface ClientResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'phone': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof ClientResponseDto
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {ClientType}
     * @memberof ClientResponseDto
     */
    'type': ClientType;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'legalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'actualAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'INN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'KPP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'OGRN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'BIK'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'paymentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'correspondentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'directorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDto
     */
    'contactPersonPhone'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientType = {
    PhysicalPerson: 'PHYSICAL_PERSON',
    LegalPerson: 'LEGAL_PERSON'
} as const;

export type ClientType = typeof ClientType[keyof typeof ClientType];


/**
 * 
 * @export
 * @interface CreateClientRequestDto
 */
export interface CreateClientRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'phone': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof CreateClientRequestDto
     */
    'beneficiary'?: Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'legalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'actualAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'INN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'KPP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'OGRN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'BIK'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'paymentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'correspondentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'directorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequestDto
     */
    'contactPersonPhone'?: string | null;
    /**
     * 
     * @type {ClientType}
     * @memberof CreateClientRequestDto
     */
    'type'?: ClientType;
}
/**
 * 
 * @export
 * @interface CreateOrderRequestDto
 */
export interface CreateOrderRequestDto {
    /**
     * 
     * @type {Priority}
     * @memberof CreateOrderRequestDto
     */
    'priority': Priority;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'equipment': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'equipmentCondition': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'serialNumberEquipment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'malfunction': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequestDto
     */
    'responsibleStaffId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequestDto
     */
    'isRemoteOrder': boolean;
}
/**
 * 
 * @export
 * @interface CreateProviderRequestDto
 */
export interface CreateProviderRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateStaffRequestDto
 */
export interface CreateStaffRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'tgId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaffRequestDto
     */
    'birthdate'?: string;
}
/**
 * 
 * @export
 * @interface CreateTaskRequestDto
 */
export interface CreateTaskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDto
     */
    'theme': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDto
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDto
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequestDto
     */
    'responsibleStaffId'?: string;
}
/**
 * 
 * @export
 * @interface CreateVendorRequestDto
 */
export interface CreateVendorRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateWarehouseItemRequestDto
 */
export interface CreateWarehouseItemRequestDto {
    /**
     * 
     * @type {Section}
     * @memberof CreateWarehouseItemRequestDto
     */
    'section': Section;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'compatibleModels'?: string | null;
    /**
     * 
     * @type {Unit}
     * @memberof CreateWarehouseItemRequestDto
     */
    'unit': Unit;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'packing'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWarehouseItemRequestDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof CreateWarehouseItemRequestDto
     */
    'expense'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateWarehouseItemRequestDto
     */
    'expenseReserve'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateWarehouseItemRequestDto
     */
    'criticalMargin': number;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'nextDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'vendorId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseItemRequestDto
     */
    'providerId': string;
}
/**
 * 
 * @export
 * @interface CreateWorkRequestDto
 */
export interface CreateWorkRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkRequestDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkRequestDto
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface DeadlineResponseDto
 */
export interface DeadlineResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DeadlineResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeadlineResponseDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DeadlineResponseDto
     */
    'normal': number;
    /**
     * 
     * @type {number}
     * @memberof DeadlineResponseDto
     */
    'urgent': number;
}
/**
 * 
 * @export
 * @interface GetAllDeadlinesDaoModel
 */
export interface GetAllDeadlinesDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetAllDeadlinesDaoModel
     */
    'id': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetAllDeadlinesDaoModel
     */
    'name': OrderStatus;
    /**
     * 
     * @type {number}
     * @memberof GetAllDeadlinesDaoModel
     */
    'normal': number;
    /**
     * 
     * @type {number}
     * @memberof GetAllDeadlinesDaoModel
     */
    'urgent': number;
}
/**
 * 
 * @export
 * @interface GetInfoAboutOrderForClientDaoModel
 */
export interface GetInfoAboutOrderForClientDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetInfoAboutOrderForClientDaoModel
     */
    'number': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetInfoAboutOrderForClientDaoModel
     */
    'status': OrderStatus;
}
/**
 * 
 * @export
 * @interface GetInfoAboutOrderForClientRequestDto
 */
export interface GetInfoAboutOrderForClientRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GetInfoAboutOrderForClientRequestDto
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof GetInfoAboutOrderForClientRequestDto
     */
    'checkCode': string;
}
/**
 * 
 * @export
 * @interface GetManyClientsDaoModel
 */
export interface GetManyClientsDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyClientsDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyClientsDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyClientsDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyClientsItem>}
     * @memberof GetManyClientsDaoModel
     */
    'data': Array<GetManyClientsItem>;
}
/**
 * 
 * @export
 * @interface GetManyClientsItem
 */
export interface GetManyClientsItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyClientsItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyClientsItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyClientsItem
     */
    'phone': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof GetManyClientsItem
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {ClientType}
     * @memberof GetManyClientsItem
     */
    'type': ClientType;
}
/**
 * 
 * @export
 * @interface GetManyClientsRequestDto
 */
export interface GetManyClientsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyClientsRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyClientsRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {OrderType}
     * @memberof GetManyClientsRequestDto
     */
    'order'?: OrderType;
    /**
     * 
     * @type {GetManyClientsSorting}
     * @memberof GetManyClientsRequestDto
     */
    'sort'?: GetManyClientsSorting;
    /**
     * Search by name, fullName, INN, directorName
     * @type {string}
     * @memberof GetManyClientsRequestDto
     */
    'search'?: string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof GetManyClientsRequestDto
     */
    'beneficiary'?: Beneficiary;
    /**
     * 
     * @type {ClientType}
     * @memberof GetManyClientsRequestDto
     */
    'type'?: ClientType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GetManyClientsSorting = {
    Name: 'name',
    Type: 'type',
    Beneficiary: 'beneficiary',
    FullName: 'fullName',
    Inn: 'INN'
} as const;

export type GetManyClientsSorting = typeof GetManyClientsSorting[keyof typeof GetManyClientsSorting];


/**
 * 
 * @export
 * @interface GetManyOrdersDaoModel
 */
export interface GetManyOrdersDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyOrdersDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrdersDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrdersDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyOrdersItem>}
     * @memberof GetManyOrdersDaoModel
     */
    'data': Array<GetManyOrdersItem>;
}
/**
 * 
 * @export
 * @interface GetManyOrdersItem
 */
export interface GetManyOrdersItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'updatedAt': string;
    /**
     * 
     * @type {Priority}
     * @memberof GetManyOrdersItem
     */
    'priority': Priority;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetManyOrdersItem
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'deadline': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'equipment': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'equipmentCondition': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'serialNumberEquipment'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'malfunction': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof GetManyOrdersItem
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof GetManyOrdersItem
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyOrdersItem
     */
    'isPaid': boolean;
    /**
     * 
     * @type {Array<GetOneOrderStage>}
     * @memberof GetManyOrdersItem
     */
    'stages': Array<GetOneOrderStage>;
    /**
     * 
     * @type {GetOneOrderClient}
     * @memberof GetManyOrdersItem
     */
    'client': GetOneOrderClient;
    /**
     * 
     * @type {GetOneOrderStaff}
     * @memberof GetManyOrdersItem
     */
    'responsibleStaff'?: GetOneOrderStaff;
}
/**
 * 
 * @export
 * @interface GetManyOrdersRequestDto
 */
export interface GetManyOrdersRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyOrdersRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrdersRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyOrdersRequestDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface GetManyProvidersDaoModel
 */
export interface GetManyProvidersDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyProvidersDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyProvidersDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyProvidersDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyProvidersItem>}
     * @memberof GetManyProvidersDaoModel
     */
    'data': Array<GetManyProvidersItem>;
}
/**
 * 
 * @export
 * @interface GetManyProvidersItem
 */
export interface GetManyProvidersItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyProvidersItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyProvidersItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyProvidersItem
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetManyProvidersRequestDto
 */
export interface GetManyProvidersRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyProvidersRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyProvidersRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof GetManyProvidersRequestDto
     */
    'search'?: string;
}
/**
 * 
 * @export
 * @interface GetManyStaffDaoModel
 */
export interface GetManyStaffDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyStaffDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyStaffDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyStaffDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyStaffItem>}
     * @memberof GetManyStaffDaoModel
     */
    'data': Array<GetManyStaffItem>;
}
/**
 * 
 * @export
 * @interface GetManyStaffItem
 */
export interface GetManyStaffItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'phone': string;
    /**
     * 
     * @type {Role}
     * @memberof GetManyStaffItem
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffItem
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyStaffItem
     */
    'isRemoved': boolean;
}
/**
 * 
 * @export
 * @interface GetManyStaffRequestDto
 */
export interface GetManyStaffRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyStaffRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyStaffRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof GetManyStaffRequestDto
     */
    'search'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyStaffRequestDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface GetManyTasksDaoModel
 */
export interface GetManyTasksDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyTasksDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTasksDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTasksDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyTasksItem>}
     * @memberof GetManyTasksDaoModel
     */
    'data': Array<GetManyTasksItem>;
}
/**
 * 
 * @export
 * @interface GetManyTasksItem
 */
export interface GetManyTasksItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyTasksItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyTasksItem
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyTasksItem
     */
    'theme': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyTasksItem
     */
    'deadline'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyTasksItem
     */
    'isRead': boolean;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GetManyTasksItem
     */
    'status': TaskStatus;
}
/**
 * 
 * @export
 * @interface GetManyTasksRequestDto
 */
export interface GetManyTasksRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyTasksRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTasksRequestDto
     */
    'limit': number;
}
/**
 * 
 * @export
 * @interface GetManyVendorsDaoModel
 */
export interface GetManyVendorsDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyVendorsDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVendorsDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVendorsDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetManyVendorsItem>}
     * @memberof GetManyVendorsDaoModel
     */
    'data': Array<GetManyVendorsItem>;
}
/**
 * 
 * @export
 * @interface GetManyVendorsItem
 */
export interface GetManyVendorsItem {
    /**
     * 
     * @type {string}
     * @memberof GetManyVendorsItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyVendorsItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetManyVendorsItem
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetManyVendorsRequestDto
 */
export interface GetManyVendorsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyVendorsRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVendorsRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof GetManyVendorsRequestDto
     */
    'search'?: string;
}
/**
 * 
 * @export
 * @interface GetManyWarehouseItemsDaoModel
 */
export interface GetManyWarehouseItemsDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyWarehouseItemsDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWarehouseItemsDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWarehouseItemsDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetOneWarehouseItemDaoModel>}
     * @memberof GetManyWarehouseItemsDaoModel
     */
    'data': Array<GetOneWarehouseItemDaoModel>;
}
/**
 * 
 * @export
 * @interface GetManyWarehouseItemsRequestDto
 */
export interface GetManyWarehouseItemsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyWarehouseItemsRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWarehouseItemsRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof GetManyWarehouseItemsRequestDto
     */
    'search'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyWarehouseItemsRequestDto
     */
    'isArchived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetManyWarehouseItemsRequestDto
     */
    'isExactMatch'?: boolean;
}
/**
 * 
 * @export
 * @interface GetManyWorksDaoModel
 */
export interface GetManyWorksDaoModel {
    /**
     * 
     * @type {number}
     * @memberof GetManyWorksDaoModel
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWorksDaoModel
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWorksDaoModel
     */
    'total': number;
    /**
     * 
     * @type {Array<GetOneWorkDaoModel>}
     * @memberof GetManyWorksDaoModel
     */
    'data': Array<GetOneWorkDaoModel>;
}
/**
 * 
 * @export
 * @interface GetManyWorksRequestDto
 */
export interface GetManyWorksRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GetManyWorksRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWorksRequestDto
     */
    'limit': number;
    /**
     * 
     * @type {string}
     * @memberof GetManyWorksRequestDto
     */
    'search'?: string;
}
/**
 * 
 * @export
 * @interface GetOneClientDaoModel
 */
export interface GetOneClientDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'phone': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof GetOneClientDaoModel
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {ClientType}
     * @memberof GetOneClientDaoModel
     */
    'type': ClientType;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'legalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'actualAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'INN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'KPP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'OGRN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'BIK'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'paymentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'correspondentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'directorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneClientDaoModel
     */
    'contactPersonPhone'?: string | null;
}
/**
 * 
 * @export
 * @interface GetOneOrderClient
 */
export interface GetOneOrderClient {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderClient
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderClient
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetOneOrderDaoModel
 */
export interface GetOneOrderDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'updatedAt': string;
    /**
     * 
     * @type {Priority}
     * @memberof GetOneOrderDaoModel
     */
    'priority': Priority;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetOneOrderDaoModel
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'deadline': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'equipment': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'equipmentCondition': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'serialNumberEquipment'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'malfunction': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof GetOneOrderDaoModel
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderDaoModel
     */
    'price': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneOrderDaoModel
     */
    'isPaid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneOrderDaoModel
     */
    'refusalToRepair'?: boolean;
    /**
     * 
     * @type {GetOneOrderStaff}
     * @memberof GetOneOrderDaoModel
     */
    'responsibleStaff'?: GetOneOrderStaff;
    /**
     * 
     * @type {Array<GetOneOrderWork>}
     * @memberof GetOneOrderDaoModel
     */
    'works'?: Array<GetOneOrderWork>;
    /**
     * 
     * @type {GetOneOrderClient}
     * @memberof GetOneOrderDaoModel
     */
    'client': GetOneOrderClient;
    /**
     * 
     * @type {Array<GetOneOrderRepairPart>}
     * @memberof GetOneOrderDaoModel
     */
    'repairParts'?: Array<GetOneOrderRepairPart>;
    /**
     * 
     * @type {Array<GetOneOrderStage>}
     * @memberof GetOneOrderDaoModel
     */
    'stages': Array<GetOneOrderStage>;
}
/**
 * 
 * @export
 * @interface GetOneOrderRepairPart
 */
export interface GetOneOrderRepairPart {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderRepairPart
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderRepairPart
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof GetOneOrderRepairPart
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderRepairPart
     */
    'cost': string;
}
/**
 * 
 * @export
 * @interface GetOneOrderStaff
 */
export interface GetOneOrderStaff {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStaff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStaff
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStaff
     */
    'lastname': string;
}
/**
 * 
 * @export
 * @interface GetOneOrderStage
 */
export interface GetOneOrderStage {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStage
     */
    'createdAt': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GetOneOrderStage
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStage
     */
    'completedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStage
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderStage
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOneOrderStage
     */
    'number': number;
}
/**
 * 
 * @export
 * @interface GetOneOrderWork
 */
export interface GetOneOrderWork {
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderWork
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderWork
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneOrderWork
     */
    'price': string;
}
/**
 * 
 * @export
 * @interface GetOneProviderDaoModel
 */
export interface GetOneProviderDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneProviderDaoModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneProviderDaoModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneProviderDaoModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetOneTaskDaoModel
 */
export interface GetOneTaskDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'theme': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'number': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GetOneTaskDaoModel
     */
    'status': TaskStatus;
    /**
     * 
     * @type {Array<GetOneTaskParticipant>}
     * @memberof GetOneTaskDaoModel
     */
    'participants': Array<GetOneTaskParticipant>;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskDaoModel
     */
    'deadline'?: string | null;
}
/**
 * 
 * @export
 * @interface GetOneTaskParticipant
 */
export interface GetOneTaskParticipant {
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskParticipant
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneTaskParticipant
     */
    'isResponsible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneTaskParticipant
     */
    'isAuthor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneTaskParticipant
     */
    'isRead': boolean;
    /**
     * 
     * @type {GetOneTaskStaff}
     * @memberof GetOneTaskParticipant
     */
    'staff': GetOneTaskStaff;
}
/**
 * 
 * @export
 * @interface GetOneTaskStaff
 */
export interface GetOneTaskStaff {
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneTaskStaff
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface GetOneVendorDaoModel
 */
export interface GetOneVendorDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneVendorDaoModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneVendorDaoModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneVendorDaoModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetOneWarehouseItemDaoModel
 */
export interface GetOneWarehouseItemDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'id': string;
    /**
     * 
     * @type {Section}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'section': Section;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'partNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'compatibleModels'?: string;
    /**
     * 
     * @type {Unit}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'unit': Unit;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'packing'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'expense'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'expenseReserve'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'criticalMargin': number;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'nextDeliveryDate'?: string;
    /**
     * 
     * @type {GetOneWarehouseItemVendor}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'vendor': GetOneWarehouseItemVendor;
    /**
     * 
     * @type {GetOneWarehouseItemVendor}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'provider': GetOneWarehouseItemVendor;
    /**
     * 
     * @type {boolean}
     * @memberof GetOneWarehouseItemDaoModel
     */
    'isArchived': boolean;
}
/**
 * 
 * @export
 * @interface GetOneWarehouseItemVendor
 */
export interface GetOneWarehouseItemVendor {
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemVendor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneWarehouseItemVendor
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface GetOneWorkDaoModel
 */
export interface GetOneWorkDaoModel {
    /**
     * 
     * @type {string}
     * @memberof GetOneWorkDaoModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GetOneWorkDaoModel
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof GetOneWorkDaoModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetOneWorkDaoModel
     */
    'price': string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlRequestDto
 */
export interface GetSignedUrlRequestDto {
    /**
     * File name
     * @type {string}
     * @memberof GetSignedUrlRequestDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface LoginViaTgRequestDto
 */
export interface LoginViaTgRequestDto {
    /**
     * 
     * @type {number}
     * @memberof LoginViaTgRequestDto
     */
    'auth_date': number;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgRequestDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgRequestDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgRequestDto
     */
    'hash': string;
    /**
     * 
     * @type {number}
     * @memberof LoginViaTgRequestDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgRequestDto
     */
    'photo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgRequestDto
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface LoginViaTgResponseDto
 */
export interface LoginViaTgResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginViaTgResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface OrderHasBeenApprovedRequestDto
 */
export interface OrderHasBeenApprovedRequestDto {
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderHasBeenApprovedRequestDto
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderHasBeenApprovedRequestDto
     */
    'deadlineDate'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderHasBeenDiagnosedRequestDto
 */
export interface OrderHasBeenDiagnosedRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderHasBeenDiagnosedRequestDto
     */
    'worksIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderHasBeenDiagnosedRequestDto
     */
    'equipmentCondition': string;
    /**
     * 
     * @type {Array<RepairPartRequestDto>}
     * @memberof OrderHasBeenDiagnosedRequestDto
     */
    'repairParts'?: Array<RepairPartRequestDto> | null;
}
/**
 * 
 * @export
 * @interface OrderReadyRequestDto
 */
export interface OrderReadyRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof OrderReadyRequestDto
     */
    'refusalToRepair'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrderResponseDto
 */
export interface OrderResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Priority}
     * @memberof OrderResponseDto
     */
    'priority': Priority;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderResponseDto
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'deadline': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'number': string;
    /**
     * 
     * @type {OrderResponseDtoResponsibleStaff}
     * @memberof OrderResponseDto
     */
    'responsibleStaff'?: OrderResponseDtoResponsibleStaff | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'equipment': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'equipmentCondition': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'serialNumberEquipment'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'malfunction': string;
    /**
     * 
     * @type {Array<WorkResponseDto>}
     * @memberof OrderResponseDto
     */
    'works'?: Array<WorkResponseDto> | null;
    /**
     * 
     * @type {ClientResponseDto}
     * @memberof OrderResponseDto
     */
    'client': ClientResponseDto;
    /**
     * 
     * @type {Beneficiary}
     * @memberof OrderResponseDto
     */
    'beneficiary': Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    'price': string;
    /**
     * 
     * @type {Array<OrderWarehouseItemResponseDto>}
     * @memberof OrderResponseDto
     */
    'repairParts'?: Array<OrderWarehouseItemResponseDto> | null;
    /**
     * 
     * @type {Array<OrderStageResponseDto>}
     * @memberof OrderResponseDto
     */
    'stages': Array<OrderStageResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderResponseDto
     */
    'isPaid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderResponseDto
     */
    'refusalToRepair'?: boolean;
}
/**
 * 
 * @export
 * @interface OrderResponseDtoResponsibleStaff
 */
export interface OrderResponseDtoResponsibleStaff {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDtoResponsibleStaff
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderStageResponseDto
 */
export interface OrderStageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof OrderStageResponseDto
     */
    'number': number;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderStageResponseDto
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderStageResponseDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    InDiagnosticsQueue: 'IN_DIAGNOSTICS_QUEUE',
    Registered: 'REGISTERED',
    Diagnostic: 'DIAGNOSTIC',
    Diagnosed: 'DIAGNOSED',
    Approved: 'APPROVED',
    ApprovedAndSparePartIsOrdered: 'APPROVED_AND_SPARE_PART_IS_ORDERED',
    InProgress: 'IN_PROGRESS',
    Ready: 'READY',
    Completed: 'COMPLETED'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderType = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type OrderType = typeof OrderType[keyof typeof OrderType];


/**
 * 
 * @export
 * @interface OrderWarehouseItemResponseDto
 */
export interface OrderWarehouseItemResponseDto {
    /**
     * 
     * @type {number}
     * @memberof OrderWarehouseItemResponseDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderWarehouseItemResponseDto
     */
    'cost': string;
    /**
     * 
     * @type {WarehouseItemResponseDto}
     * @memberof OrderWarehouseItemResponseDto
     */
    'warehouseItem': WarehouseItemResponseDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Priority = {
    Normal: 'NORMAL',
    Urgent: 'URGENT'
} as const;

export type Priority = typeof Priority[keyof typeof Priority];


/**
 * 
 * @export
 * @interface ProviderResponseDto
 */
export interface ProviderResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProviderResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderResponseDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderResponseDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface RepairPartRequestDto
 */
export interface RepairPartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RepairPartRequestDto
     */
    'warehouseItemId': string;
    /**
     * 
     * @type {number}
     * @memberof RepairPartRequestDto
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    Admin: 'admin',
    Engineer: 'engineer',
    Manager: 'manager',
    Dispatcher: 'dispatcher'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @enum {string}
 */

export const Section = {
    Material: 'MATERIAL',
    Product: 'PRODUCT'
} as const;

export type Section = typeof Section[keyof typeof Section];


/**
 * 
 * @export
 * @interface SetWebhookRequestDto
 */
export interface SetWebhookRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SetWebhookRequestDto
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface StaffGetMeDaoModel
 */
export interface StaffGetMeDaoModel {
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'phone': string;
    /**
     * 
     * @type {Role}
     * @memberof StaffGetMeDaoModel
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffGetMeDaoModel
     */
    'birthdate'?: string | null;
}
/**
 * 
 * @export
 * @interface StaffResponseDto
 */
export interface StaffResponseDto {
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffResponseDto
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface TaskResponseDto
 */
export interface TaskResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'theme': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'number': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskResponseDto
     */
    'status': TaskStatus;
    /**
     * 
     * @type {Array<TaskStaffResponseDto>}
     * @memberof TaskResponseDto
     */
    'participants': Array<TaskStaffResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDto
     */
    'deadline'?: string;
}
/**
 * 
 * @export
 * @interface TaskStaffResponseDto
 */
export interface TaskStaffResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TaskStaffResponseDto
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskStaffResponseDto
     */
    'isResponsible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskStaffResponseDto
     */
    'isAuthor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskStaffResponseDto
     */
    'isRead': boolean;
    /**
     * 
     * @type {StaffResponseDto}
     * @memberof TaskStaffResponseDto
     */
    'staff': StaffResponseDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    Registered: 'REGISTERED',
    InWork: 'IN_WORK',
    Completed: 'COMPLETED'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const Unit = {
    Kg: 'KG',
    Piece: 'PIECE',
    Set: 'SET'
} as const;

export type Unit = typeof Unit[keyof typeof Unit];


/**
 * 
 * @export
 * @interface UpdateClientRequestDto
 */
export interface UpdateClientRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'phone': string;
    /**
     * 
     * @type {Beneficiary}
     * @memberof UpdateClientRequestDto
     */
    'beneficiary'?: Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'legalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'actualAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'INN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'KPP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'OGRN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'BIK'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'paymentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'correspondentAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'directorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'contactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequestDto
     */
    'contactPersonPhone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateDeadlineRequestDto
 */
export interface UpdateDeadlineRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDeadlineRequestDto
     */
    'normal': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeadlineRequestDto
     */
    'urgent': number;
}
/**
 * 
 * @export
 * @interface UpdateOrderRequestDto
 */
export interface UpdateOrderRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrderRequestDto
     */
    'isPaid'?: boolean | null;
    /**
     * 
     * @type {Beneficiary}
     * @memberof UpdateOrderRequestDto
     */
    'beneficiary'?: Beneficiary;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequestDto
     */
    'price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequestDto
     */
    'responsibleStaffId'?: string | null;
    /**
     * in minutes
     * @type {number}
     * @memberof UpdateOrderRequestDto
     */
    'deadline'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProviderRequestDto
 */
export interface UpdateProviderRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateStaffRequestDto
 */
export interface UpdateStaffRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'birthdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaffRequestDto
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateTaskRequestDto
 */
export interface UpdateTaskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDto
     */
    'theme': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDto
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDto
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequestDto
     */
    'responsibleStaffId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateVendorRequestDto
 */
export interface UpdateVendorRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateWarehouseItemRequestDto
 */
export interface UpdateWarehouseItemRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'compatibleModels'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'packing'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'expense'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'expenseReserve'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'criticalMargin': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'nextDeliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'vendorId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseItemRequestDto
     */
    'providerId': string;
}
/**
 * 
 * @export
 * @interface UpdateWorkRequestDto
 */
export interface UpdateWorkRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkRequestDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkRequestDto
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface VendorResponseDto
 */
export interface VendorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface WarehouseItemResponseDto
 */
export interface WarehouseItemResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'id': string;
    /**
     * 
     * @type {Section}
     * @memberof WarehouseItemResponseDto
     */
    'section': Section;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'partNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'compatibleModels'?: string;
    /**
     * 
     * @type {Unit}
     * @memberof WarehouseItemResponseDto
     */
    'unit': Unit;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'packing'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseItemResponseDto
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseItemResponseDto
     */
    'expense'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseItemResponseDto
     */
    'expenseReserve'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseItemResponseDto
     */
    'criticalMargin': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseItemResponseDto
     */
    'nextDeliveryDate'?: string;
    /**
     * 
     * @type {object}
     * @memberof WarehouseItemResponseDto
     */
    'vendor': object;
    /**
     * 
     * @type {object}
     * @memberof WarehouseItemResponseDto
     */
    'provider': object;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseItemResponseDto
     */
    'isArchived': boolean;
}
/**
 * 
 * @export
 * @interface WorkResponseDto
 */
export interface WorkResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WorkResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkResponseDto
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof WorkResponseDto
     */
    'time': number;
}

/**
 * OrderClientApi - axios parameter creator
 * @export
 */
export const OrderClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create client
         * @param {CreateClientRequestDto} createClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientControllerCreateClient: async (createClientRequestDto: CreateClientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequestDto' is not null or undefined
            assertParamExists('createClientControllerCreateClient', 'createClientRequestDto', createClientRequestDto)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many clients
         * @param {number} page 
         * @param {number} limit 
         * @param {OrderType} [order] 
         * @param {GetManyClientsSorting} [sort] 
         * @param {string} [search] Search by name, fullName, INN, directorName
         * @param {Beneficiary} [beneficiary] 
         * @param {ClientType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyClientsControllerGetManyClients: async (page: number, limit: number, order?: OrderType, sort?: GetManyClientsSorting, search?: string, beneficiary?: Beneficiary, type?: ClientType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyClientsControllerGetManyClients', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyClientsControllerGetManyClients', 'limit', limit)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (beneficiary !== undefined) {
                localVarQueryParameter['beneficiary'] = beneficiary;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneClientControllerGetOneClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneClientControllerGetOneClient', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeClientControllerRemoveClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeClientControllerRemoveClient', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update client
         * @param {string} id 
         * @param {UpdateClientRequestDto} updateClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientControllerUpdateClient: async (id: string, updateClientRequestDto: UpdateClientRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientControllerUpdateClient', 'id', id)
            // verify required parameter 'updateClientRequestDto' is not null or undefined
            assertParamExists('updateClientControllerUpdateClient', 'updateClientRequestDto', updateClientRequestDto)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderClientApi - functional programming interface
 * @export
 */
export const OrderClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create client
         * @param {CreateClientRequestDto} createClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientControllerCreateClient(createClientRequestDto: CreateClientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientControllerCreateClient(createClientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many clients
         * @param {number} page 
         * @param {number} limit 
         * @param {OrderType} [order] 
         * @param {GetManyClientsSorting} [sort] 
         * @param {string} [search] Search by name, fullName, INN, directorName
         * @param {Beneficiary} [beneficiary] 
         * @param {ClientType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyClientsControllerGetManyClients(page: number, limit: number, order?: OrderType, sort?: GetManyClientsSorting, search?: string, beneficiary?: Beneficiary, type?: ClientType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyClientsDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyClientsControllerGetManyClients(page, limit, order, sort, search, beneficiary, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneClientControllerGetOneClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneClientDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneClientControllerGetOneClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeClientControllerRemoveClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeClientControllerRemoveClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update client
         * @param {string} id 
         * @param {UpdateClientRequestDto} updateClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientControllerUpdateClient(id: string, updateClientRequestDto: UpdateClientRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientControllerUpdateClient(id, updateClientRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderClientApi - factory interface
 * @export
 */
export const OrderClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Create client
         * @param {CreateClientRequestDto} createClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientControllerCreateClient(createClientRequestDto: CreateClientRequestDto, options?: any): AxiosPromise<ClientResponseDto> {
            return localVarFp.createClientControllerCreateClient(createClientRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many clients
         * @param {number} page 
         * @param {number} limit 
         * @param {OrderType} [order] 
         * @param {GetManyClientsSorting} [sort] 
         * @param {string} [search] Search by name, fullName, INN, directorName
         * @param {Beneficiary} [beneficiary] 
         * @param {ClientType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyClientsControllerGetManyClients(page: number, limit: number, order?: OrderType, sort?: GetManyClientsSorting, search?: string, beneficiary?: Beneficiary, type?: ClientType, options?: any): AxiosPromise<GetManyClientsDaoModel> {
            return localVarFp.getManyClientsControllerGetManyClients(page, limit, order, sort, search, beneficiary, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneClientControllerGetOneClient(id: string, options?: any): AxiosPromise<GetOneClientDaoModel> {
            return localVarFp.getOneClientControllerGetOneClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove client
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeClientControllerRemoveClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeClientControllerRemoveClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update client
         * @param {string} id 
         * @param {UpdateClientRequestDto} updateClientRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientControllerUpdateClient(id: string, updateClientRequestDto: UpdateClientRequestDto, options?: any): AxiosPromise<ClientResponseDto> {
            return localVarFp.updateClientControllerUpdateClient(id, updateClientRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderClientApi - object-oriented interface
 * @export
 * @class OrderClientApi
 * @extends {BaseAPI}
 */
export class OrderClientApi extends BaseAPI {
    /**
     * 
     * @summary Create client
     * @param {CreateClientRequestDto} createClientRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderClientApi
     */
    public createClientControllerCreateClient(createClientRequestDto: CreateClientRequestDto, options?: AxiosRequestConfig) {
        return OrderClientApiFp(this.configuration).createClientControllerCreateClient(createClientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many clients
     * @param {number} page 
     * @param {number} limit 
     * @param {OrderType} [order] 
     * @param {GetManyClientsSorting} [sort] 
     * @param {string} [search] Search by name, fullName, INN, directorName
     * @param {Beneficiary} [beneficiary] 
     * @param {ClientType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderClientApi
     */
    public getManyClientsControllerGetManyClients(page: number, limit: number, order?: OrderType, sort?: GetManyClientsSorting, search?: string, beneficiary?: Beneficiary, type?: ClientType, options?: AxiosRequestConfig) {
        return OrderClientApiFp(this.configuration).getManyClientsControllerGetManyClients(page, limit, order, sort, search, beneficiary, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderClientApi
     */
    public getOneClientControllerGetOneClient(id: string, options?: AxiosRequestConfig) {
        return OrderClientApiFp(this.configuration).getOneClientControllerGetOneClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove client
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderClientApi
     */
    public removeClientControllerRemoveClient(id: string, options?: AxiosRequestConfig) {
        return OrderClientApiFp(this.configuration).removeClientControllerRemoveClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update client
     * @param {string} id 
     * @param {UpdateClientRequestDto} updateClientRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderClientApi
     */
    public updateClientControllerUpdateClient(id: string, updateClientRequestDto: UpdateClientRequestDto, options?: AxiosRequestConfig) {
        return OrderClientApiFp(this.configuration).updateClientControllerUpdateClient(id, updateClientRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderDeadlineApi - axios parameter creator
 * @export
 */
export const OrderDeadlineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeadlinesControllerGetAllDeadlines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deadlines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update deadline
         * @param {string} id 
         * @param {UpdateDeadlineRequestDto} updateDeadlineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeadlineControllerUpdateDeadline: async (id: string, updateDeadlineRequestDto: UpdateDeadlineRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeadlineControllerUpdateDeadline', 'id', id)
            // verify required parameter 'updateDeadlineRequestDto' is not null or undefined
            assertParamExists('updateDeadlineControllerUpdateDeadline', 'updateDeadlineRequestDto', updateDeadlineRequestDto)
            const localVarPath = `/deadlines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeadlineRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderDeadlineApi - functional programming interface
 * @export
 */
export const OrderDeadlineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderDeadlineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDeadlinesControllerGetAllDeadlines(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllDeadlinesDaoModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDeadlinesControllerGetAllDeadlines(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update deadline
         * @param {string} id 
         * @param {UpdateDeadlineRequestDto} updateDeadlineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeadlineControllerUpdateDeadline(id: string, updateDeadlineRequestDto: UpdateDeadlineRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeadlineResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeadlineControllerUpdateDeadline(id, updateDeadlineRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderDeadlineApi - factory interface
 * @export
 */
export const OrderDeadlineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderDeadlineApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeadlinesControllerGetAllDeadlines(options?: any): AxiosPromise<Array<GetAllDeadlinesDaoModel>> {
            return localVarFp.getAllDeadlinesControllerGetAllDeadlines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update deadline
         * @param {string} id 
         * @param {UpdateDeadlineRequestDto} updateDeadlineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeadlineControllerUpdateDeadline(id: string, updateDeadlineRequestDto: UpdateDeadlineRequestDto, options?: any): AxiosPromise<DeadlineResponseDto> {
            return localVarFp.updateDeadlineControllerUpdateDeadline(id, updateDeadlineRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderDeadlineApi - object-oriented interface
 * @export
 * @class OrderDeadlineApi
 * @extends {BaseAPI}
 */
export class OrderDeadlineApi extends BaseAPI {
    /**
     * 
     * @summary Get all deadlines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDeadlineApi
     */
    public getAllDeadlinesControllerGetAllDeadlines(options?: AxiosRequestConfig) {
        return OrderDeadlineApiFp(this.configuration).getAllDeadlinesControllerGetAllDeadlines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update deadline
     * @param {string} id 
     * @param {UpdateDeadlineRequestDto} updateDeadlineRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDeadlineApi
     */
    public updateDeadlineControllerUpdateDeadline(id: string, updateDeadlineRequestDto: UpdateDeadlineRequestDto, options?: AxiosRequestConfig) {
        return OrderDeadlineApiFp(this.configuration).updateDeadlineControllerUpdateDeadline(id, updateDeadlineRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderOrderApi - axios parameter creator
 * @export
 */
export const OrderOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Complete order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOrderControllerCompleteOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeOrderControllerCompleteOrder', 'id', id)
            const localVarPath = `/orders/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create order
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderControllerCreateOrder: async (createOrderRequestDto: CreateOrderRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequestDto' is not null or undefined
            assertParamExists('createOrderControllerCreateOrder', 'createOrderRequestDto', createOrderRequestDto)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get certificate of technical condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition', 'id', id)
            const localVarPath = `/orders/{id}/certificate-of-technical-condition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about order for client
         * @param {string} number 
         * @param {string} checkCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient: async (number: string, checkCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient', 'number', number)
            // verify required parameter 'checkCode' is not null or undefined
            assertParamExists('getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient', 'checkCode', checkCode)
            const localVarPath = `/info-about-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }

            if (checkCode !== undefined) {
                localVarQueryParameter['checkCode'] = checkCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many orders
         * @param {number} page 
         * @param {number} limit 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyOrdersControllerGetManyOrders: async (page: number, limit: number, isActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyOrdersControllerGetManyOrders', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyOrdersControllerGetManyOrders', 'limit', limit)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneOrderControllerGetOneOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneOrderControllerGetOneOrder', 'id', id)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order has been approved
         * @param {string} id 
         * @param {OrderHasBeenApprovedRequestDto} orderHasBeenApprovedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderHasBeenApprovedControllerOrderHasBeenApproved: async (id: string, orderHasBeenApprovedRequestDto: OrderHasBeenApprovedRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderHasBeenApprovedControllerOrderHasBeenApproved', 'id', id)
            // verify required parameter 'orderHasBeenApprovedRequestDto' is not null or undefined
            assertParamExists('orderHasBeenApprovedControllerOrderHasBeenApproved', 'orderHasBeenApprovedRequestDto', orderHasBeenApprovedRequestDto)
            const localVarPath = `/orders/{id}/approved`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderHasBeenApprovedRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order has been diagnosed
         * @param {string} id 
         * @param {OrderHasBeenDiagnosedRequestDto} orderHasBeenDiagnosedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed: async (id: string, orderHasBeenDiagnosedRequestDto: OrderHasBeenDiagnosedRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed', 'id', id)
            // verify required parameter 'orderHasBeenDiagnosedRequestDto' is not null or undefined
            assertParamExists('orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed', 'orderHasBeenDiagnosedRequestDto', orderHasBeenDiagnosedRequestDto)
            const localVarPath = `/orders/{id}/diagnosed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderHasBeenDiagnosedRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order ready
         * @param {string} id 
         * @param {OrderReadyRequestDto} orderReadyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReadyControllerOrderReady: async (id: string, orderReadyRequestDto: OrderReadyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderReadyControllerOrderReady', 'id', id)
            // verify required parameter 'orderReadyRequestDto' is not null or undefined
            assertParamExists('orderReadyControllerOrderReady', 'orderReadyRequestDto', orderReadyRequestDto)
            const localVarPath = `/orders/{id}/ready`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderReadyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put in queue for diagnostics
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInQueueForDiagnosticsControllerPutInQueueForDiagnostics: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putInQueueForDiagnosticsControllerPutInQueueForDiagnostics', 'id', id)
            const localVarPath = `/orders/{id}/in-queue-for-diagnostics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start diagnostic
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDiagnosticControllerStartDiagnostic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startDiagnosticControllerStartDiagnostic', 'id', id)
            const localVarPath = `/orders/{id}/start-diagnostic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Take order to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeOrderToWorkControllerTakeOrderToWork: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('takeOrderToWorkControllerTakeOrderToWork', 'id', id)
            const localVarPath = `/orders/{id}/take-to-work`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order
         * @param {string} id 
         * @param {UpdateOrderRequestDto} updateOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderControllerUpdateOrder: async (id: string, updateOrderRequestDto: UpdateOrderRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderControllerUpdateOrder', 'id', id)
            // verify required parameter 'updateOrderRequestDto' is not null or undefined
            assertParamExists('updateOrderControllerUpdateOrder', 'updateOrderRequestDto', updateOrderRequestDto)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderOrderApi - functional programming interface
 * @export
 */
export const OrderOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Complete order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeOrderControllerCompleteOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeOrderControllerCompleteOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create order
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderControllerCreateOrder(createOrderRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get certificate of technical condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about order for client
         * @param {string} number 
         * @param {string} checkCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number: string, checkCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInfoAboutOrderForClientDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number, checkCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many orders
         * @param {number} page 
         * @param {number} limit 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyOrdersControllerGetManyOrders(page: number, limit: number, isActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyOrdersDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyOrdersControllerGetManyOrders(page, limit, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneOrderControllerGetOneOrder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneOrderDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneOrderControllerGetOneOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order has been approved
         * @param {string} id 
         * @param {OrderHasBeenApprovedRequestDto} orderHasBeenApprovedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderHasBeenApprovedControllerOrderHasBeenApproved(id: string, orderHasBeenApprovedRequestDto: OrderHasBeenApprovedRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderHasBeenApprovedControllerOrderHasBeenApproved(id, orderHasBeenApprovedRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order has been diagnosed
         * @param {string} id 
         * @param {OrderHasBeenDiagnosedRequestDto} orderHasBeenDiagnosedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id: string, orderHasBeenDiagnosedRequestDto: OrderHasBeenDiagnosedRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id, orderHasBeenDiagnosedRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order ready
         * @param {string} id 
         * @param {OrderReadyRequestDto} orderReadyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReadyControllerOrderReady(id: string, orderReadyRequestDto: OrderReadyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReadyControllerOrderReady(id, orderReadyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put in queue for diagnostics
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start diagnostic
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startDiagnosticControllerStartDiagnostic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startDiagnosticControllerStartDiagnostic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Take order to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeOrderToWorkControllerTakeOrderToWork(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeOrderToWorkControllerTakeOrderToWork(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update order
         * @param {string} id 
         * @param {UpdateOrderRequestDto} updateOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderControllerUpdateOrder(id: string, updateOrderRequestDto: UpdateOrderRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderControllerUpdateOrder(id, updateOrderRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderOrderApi - factory interface
 * @export
 */
export const OrderOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Complete order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOrderControllerCompleteOrder(id: string, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.completeOrderControllerCompleteOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create order
         * @param {CreateOrderRequestDto} createOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.createOrderControllerCreateOrder(createOrderRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get certificate of technical condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about order for client
         * @param {string} number 
         * @param {string} checkCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number: string, checkCode: string, options?: any): AxiosPromise<GetInfoAboutOrderForClientDaoModel> {
            return localVarFp.getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number, checkCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many orders
         * @param {number} page 
         * @param {number} limit 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyOrdersControllerGetManyOrders(page: number, limit: number, isActive?: boolean, options?: any): AxiosPromise<GetManyOrdersDaoModel> {
            return localVarFp.getManyOrdersControllerGetManyOrders(page, limit, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneOrderControllerGetOneOrder(id: string, options?: any): AxiosPromise<GetOneOrderDaoModel> {
            return localVarFp.getOneOrderControllerGetOneOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order has been approved
         * @param {string} id 
         * @param {OrderHasBeenApprovedRequestDto} orderHasBeenApprovedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderHasBeenApprovedControllerOrderHasBeenApproved(id: string, orderHasBeenApprovedRequestDto: OrderHasBeenApprovedRequestDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.orderHasBeenApprovedControllerOrderHasBeenApproved(id, orderHasBeenApprovedRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order has been diagnosed
         * @param {string} id 
         * @param {OrderHasBeenDiagnosedRequestDto} orderHasBeenDiagnosedRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id: string, orderHasBeenDiagnosedRequestDto: OrderHasBeenDiagnosedRequestDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id, orderHasBeenDiagnosedRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order ready
         * @param {string} id 
         * @param {OrderReadyRequestDto} orderReadyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReadyControllerOrderReady(id: string, orderReadyRequestDto: OrderReadyRequestDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.orderReadyControllerOrderReady(id, orderReadyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put in queue for diagnostics
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id: string, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start diagnostic
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDiagnosticControllerStartDiagnostic(id: string, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.startDiagnosticControllerStartDiagnostic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Take order to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeOrderToWorkControllerTakeOrderToWork(id: string, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.takeOrderToWorkControllerTakeOrderToWork(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order
         * @param {string} id 
         * @param {UpdateOrderRequestDto} updateOrderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderControllerUpdateOrder(id: string, updateOrderRequestDto: UpdateOrderRequestDto, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.updateOrderControllerUpdateOrder(id, updateOrderRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderOrderApi - object-oriented interface
 * @export
 * @class OrderOrderApi
 * @extends {BaseAPI}
 */
export class OrderOrderApi extends BaseAPI {
    /**
     * 
     * @summary Complete order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public completeOrderControllerCompleteOrder(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).completeOrderControllerCompleteOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create order
     * @param {CreateOrderRequestDto} createOrderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public createOrderControllerCreateOrder(createOrderRequestDto: CreateOrderRequestDto, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).createOrderControllerCreateOrder(createOrderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get certificate of technical condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).getCertificateOfTechnicalConditionControllerGetCertificateOfTechnicalCondition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about order for client
     * @param {string} number 
     * @param {string} checkCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number: string, checkCode: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).getInfoAboutOrderForClientControllerGetInfoAboutOrderForClient(number, checkCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many orders
     * @param {number} page 
     * @param {number} limit 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public getManyOrdersControllerGetManyOrders(page: number, limit: number, isActive?: boolean, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).getManyOrdersControllerGetManyOrders(page, limit, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public getOneOrderControllerGetOneOrder(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).getOneOrderControllerGetOneOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order has been approved
     * @param {string} id 
     * @param {OrderHasBeenApprovedRequestDto} orderHasBeenApprovedRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public orderHasBeenApprovedControllerOrderHasBeenApproved(id: string, orderHasBeenApprovedRequestDto: OrderHasBeenApprovedRequestDto, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).orderHasBeenApprovedControllerOrderHasBeenApproved(id, orderHasBeenApprovedRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order has been diagnosed
     * @param {string} id 
     * @param {OrderHasBeenDiagnosedRequestDto} orderHasBeenDiagnosedRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id: string, orderHasBeenDiagnosedRequestDto: OrderHasBeenDiagnosedRequestDto, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).orderHasBeenDiagnosedControllerOrderHasBeenDiagnosed(id, orderHasBeenDiagnosedRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order ready
     * @param {string} id 
     * @param {OrderReadyRequestDto} orderReadyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public orderReadyControllerOrderReady(id: string, orderReadyRequestDto: OrderReadyRequestDto, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).orderReadyControllerOrderReady(id, orderReadyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put in queue for diagnostics
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).putInQueueForDiagnosticsControllerPutInQueueForDiagnostics(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start diagnostic
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public startDiagnosticControllerStartDiagnostic(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).startDiagnosticControllerStartDiagnostic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Take order to work
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public takeOrderToWorkControllerTakeOrderToWork(id: string, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).takeOrderToWorkControllerTakeOrderToWork(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order
     * @param {string} id 
     * @param {UpdateOrderRequestDto} updateOrderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderOrderApi
     */
    public updateOrderControllerUpdateOrder(id: string, updateOrderRequestDto: UpdateOrderRequestDto, options?: AxiosRequestConfig) {
        return OrderOrderApiFp(this.configuration).updateOrderControllerUpdateOrder(id, updateOrderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderWorkApi - axios parameter creator
 * @export
 */
export const OrderWorkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create work
         * @param {CreateWorkRequestDto} createWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkControllerCreateWork: async (createWorkRequestDto: CreateWorkRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWorkRequestDto' is not null or undefined
            assertParamExists('createWorkControllerCreateWork', 'createWorkRequestDto', createWorkRequestDto)
            const localVarPath = `/works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many works
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyWorksControllerGetManyWorks: async (page: number, limit: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyWorksControllerGetManyWorks', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyWorksControllerGetManyWorks', 'limit', limit)
            const localVarPath = `/works`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneWorkControllerGetOneWork: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneWorkControllerGetOneWork', 'id', id)
            const localVarPath = `/works/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkControllerRemoveWork: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeWorkControllerRemoveWork', 'id', id)
            const localVarPath = `/works/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update work
         * @param {string} id 
         * @param {UpdateWorkRequestDto} updateWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkControllerUpdateWork: async (id: string, updateWorkRequestDto: UpdateWorkRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkControllerUpdateWork', 'id', id)
            // verify required parameter 'updateWorkRequestDto' is not null or undefined
            assertParamExists('updateWorkControllerUpdateWork', 'updateWorkRequestDto', updateWorkRequestDto)
            const localVarPath = `/works/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderWorkApi - functional programming interface
 * @export
 */
export const OrderWorkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderWorkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create work
         * @param {CreateWorkRequestDto} createWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkControllerCreateWork(createWorkRequestDto: CreateWorkRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkControllerCreateWork(createWorkRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many works
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyWorksControllerGetManyWorks(page: number, limit: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyWorksDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyWorksControllerGetManyWorks(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneWorkControllerGetOneWork(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneWorkDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneWorkControllerGetOneWork(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWorkControllerRemoveWork(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWorkControllerRemoveWork(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update work
         * @param {string} id 
         * @param {UpdateWorkRequestDto} updateWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkControllerUpdateWork(id: string, updateWorkRequestDto: UpdateWorkRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkControllerUpdateWork(id, updateWorkRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderWorkApi - factory interface
 * @export
 */
export const OrderWorkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderWorkApiFp(configuration)
    return {
        /**
         * 
         * @summary Create work
         * @param {CreateWorkRequestDto} createWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkControllerCreateWork(createWorkRequestDto: CreateWorkRequestDto, options?: any): AxiosPromise<WorkResponseDto> {
            return localVarFp.createWorkControllerCreateWork(createWorkRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many works
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyWorksControllerGetManyWorks(page: number, limit: number, search?: string, options?: any): AxiosPromise<GetManyWorksDaoModel> {
            return localVarFp.getManyWorksControllerGetManyWorks(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneWorkControllerGetOneWork(id: string, options?: any): AxiosPromise<GetOneWorkDaoModel> {
            return localVarFp.getOneWorkControllerGetOneWork(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkControllerRemoveWork(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWorkControllerRemoveWork(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update work
         * @param {string} id 
         * @param {UpdateWorkRequestDto} updateWorkRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkControllerUpdateWork(id: string, updateWorkRequestDto: UpdateWorkRequestDto, options?: any): AxiosPromise<WorkResponseDto> {
            return localVarFp.updateWorkControllerUpdateWork(id, updateWorkRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderWorkApi - object-oriented interface
 * @export
 * @class OrderWorkApi
 * @extends {BaseAPI}
 */
export class OrderWorkApi extends BaseAPI {
    /**
     * 
     * @summary Create work
     * @param {CreateWorkRequestDto} createWorkRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWorkApi
     */
    public createWorkControllerCreateWork(createWorkRequestDto: CreateWorkRequestDto, options?: AxiosRequestConfig) {
        return OrderWorkApiFp(this.configuration).createWorkControllerCreateWork(createWorkRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many works
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWorkApi
     */
    public getManyWorksControllerGetManyWorks(page: number, limit: number, search?: string, options?: AxiosRequestConfig) {
        return OrderWorkApiFp(this.configuration).getManyWorksControllerGetManyWorks(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one work
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWorkApi
     */
    public getOneWorkControllerGetOneWork(id: string, options?: AxiosRequestConfig) {
        return OrderWorkApiFp(this.configuration).getOneWorkControllerGetOneWork(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove work
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWorkApi
     */
    public removeWorkControllerRemoveWork(id: string, options?: AxiosRequestConfig) {
        return OrderWorkApiFp(this.configuration).removeWorkControllerRemoveWork(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update work
     * @param {string} id 
     * @param {UpdateWorkRequestDto} updateWorkRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWorkApi
     */
    public updateWorkControllerUpdateWork(id: string, updateWorkRequestDto: UpdateWorkRequestDto, options?: AxiosRequestConfig) {
        return OrderWorkApiFp(this.configuration).updateWorkControllerUpdateWork(id, updateWorkRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create staff
         * @param {CreateStaffRequestDto} createStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffControllerCreateUser: async (createStaffRequestDto: CreateStaffRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStaffRequestDto' is not null or undefined
            assertParamExists('createStaffControllerCreateUser', 'createStaffRequestDto', createStaffRequestDto)
            const localVarPath = `/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStaffRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many staff
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyStaffControllerGetManyStaff: async (page: number, limit: number, search?: string, isActive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyStaffControllerGetManyStaff', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyStaffControllerGetManyStaff', 'limit', limit)
            const localVarPath = `/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffControllerGetStaff: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStaffControllerGetStaff', 'id', id)
            const localVarPath = `/staff/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStaffControllerRemoveUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeStaffControllerRemoveUser', 'id', id)
            const localVarPath = `/staff/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Staff get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffGetMeControllerStaffGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update staff
         * @param {UpdateStaffRequestDto} updateStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffControllerUpdateUser: async (updateStaffRequestDto: UpdateStaffRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStaffRequestDto' is not null or undefined
            assertParamExists('updateStaffControllerUpdateUser', 'updateStaffRequestDto', updateStaffRequestDto)
            const localVarPath = `/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStaffRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create staff
         * @param {CreateStaffRequestDto} createStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStaffControllerCreateUser(createStaffRequestDto: CreateStaffRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStaffControllerCreateUser(createStaffRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many staff
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyStaffControllerGetManyStaff(page: number, limit: number, search?: string, isActive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyStaffDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyStaffControllerGetManyStaff(page, limit, search, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffControllerGetStaff(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyStaffItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffControllerGetStaff(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeStaffControllerRemoveUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeStaffControllerRemoveUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Staff get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async staffGetMeControllerStaffGetMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffGetMeDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.staffGetMeControllerStaffGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update staff
         * @param {UpdateStaffRequestDto} updateStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStaffControllerUpdateUser(updateStaffRequestDto: UpdateStaffRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStaffControllerUpdateUser(updateStaffRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffApiFp(configuration)
    return {
        /**
         * 
         * @summary Create staff
         * @param {CreateStaffRequestDto} createStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffControllerCreateUser(createStaffRequestDto: CreateStaffRequestDto, options?: any): AxiosPromise<StaffResponseDto> {
            return localVarFp.createStaffControllerCreateUser(createStaffRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many staff
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyStaffControllerGetManyStaff(page: number, limit: number, search?: string, isActive?: boolean, options?: any): AxiosPromise<GetManyStaffDaoModel> {
            return localVarFp.getManyStaffControllerGetManyStaff(page, limit, search, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffControllerGetStaff(id: string, options?: any): AxiosPromise<GetManyStaffItem> {
            return localVarFp.getStaffControllerGetStaff(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove staff
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStaffControllerRemoveUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeStaffControllerRemoveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Staff get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffGetMeControllerStaffGetMe(options?: any): AxiosPromise<StaffGetMeDaoModel> {
            return localVarFp.staffGetMeControllerStaffGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update staff
         * @param {UpdateStaffRequestDto} updateStaffRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffControllerUpdateUser(updateStaffRequestDto: UpdateStaffRequestDto, options?: any): AxiosPromise<StaffResponseDto> {
            return localVarFp.updateStaffControllerUpdateUser(updateStaffRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @summary Create staff
     * @param {CreateStaffRequestDto} createStaffRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public createStaffControllerCreateUser(createStaffRequestDto: CreateStaffRequestDto, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).createStaffControllerCreateUser(createStaffRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many staff
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [search] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getManyStaffControllerGetManyStaff(page: number, limit: number, search?: string, isActive?: boolean, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).getManyStaffControllerGetManyStaff(page, limit, search, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get staff
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getStaffControllerGetStaff(id: string, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).getStaffControllerGetStaff(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove staff
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public removeStaffControllerRemoveUser(id: string, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).removeStaffControllerRemoveUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Staff get me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public staffGetMeControllerStaffGetMe(options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).staffGetMeControllerStaffGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update staff
     * @param {UpdateStaffRequestDto} updateStaffRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public updateStaffControllerUpdateUser(updateStaffRequestDto: UpdateStaffRequestDto, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).updateStaffControllerUpdateUser(updateStaffRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaffAuthApi - axios parameter creator
 * @export
 */
export const StaffAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login via telegram
         * @param {LoginViaTgRequestDto} loginViaTgRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginViaTgControllerLoginViaTg: async (loginViaTgRequestDto: LoginViaTgRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginViaTgRequestDto' is not null or undefined
            assertParamExists('loginViaTgControllerLoginViaTg', 'loginViaTgRequestDto', loginViaTgRequestDto)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginViaTgRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffAuthApi - functional programming interface
 * @export
 */
export const StaffAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login via telegram
         * @param {LoginViaTgRequestDto} loginViaTgRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginViaTgControllerLoginViaTg(loginViaTgRequestDto: LoginViaTgRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginViaTgResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginViaTgControllerLoginViaTg(loginViaTgRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaffAuthApi - factory interface
 * @export
 */
export const StaffAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login via telegram
         * @param {LoginViaTgRequestDto} loginViaTgRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginViaTgControllerLoginViaTg(loginViaTgRequestDto: LoginViaTgRequestDto, options?: any): AxiosPromise<LoginViaTgResponseDto> {
            return localVarFp.loginViaTgControllerLoginViaTg(loginViaTgRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffAuthApi - object-oriented interface
 * @export
 * @class StaffAuthApi
 * @extends {BaseAPI}
 */
export class StaffAuthApi extends BaseAPI {
    /**
     * 
     * @summary Login via telegram
     * @param {LoginViaTgRequestDto} loginViaTgRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffAuthApi
     */
    public loginViaTgControllerLoginViaTg(loginViaTgRequestDto: LoginViaTgRequestDto, options?: AxiosRequestConfig) {
        return StaffAuthApiFp(this.configuration).loginViaTgControllerLoginViaTg(loginViaTgRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageStorageApi - axios parameter creator
 * @export
 */
export const StorageStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get signed url
         * @param {string} fileName File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlControllerGetSignedUrl: async (fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getSignedUrlControllerGetSignedUrl', 'fileName', fileName)
            const localVarPath = `/storage/get-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageStorageApi - functional programming interface
 * @export
 */
export const StorageStorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageStorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get signed url
         * @param {string} fileName File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlControllerGetSignedUrl(fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrlControllerGetSignedUrl(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageStorageApi - factory interface
 * @export
 */
export const StorageStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageStorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get signed url
         * @param {string} fileName File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlControllerGetSignedUrl(fileName: string, options?: any): AxiosPromise<void> {
            return localVarFp.getSignedUrlControllerGetSignedUrl(fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageStorageApi - object-oriented interface
 * @export
 * @class StorageStorageApi
 * @extends {BaseAPI}
 */
export class StorageStorageApi extends BaseAPI {
    /**
     * 
     * @summary Get signed url
     * @param {string} fileName File name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageStorageApi
     */
    public getSignedUrlControllerGetSignedUrl(fileName: string, options?: AxiosRequestConfig) {
        return StorageStorageApiFp(this.configuration).getSignedUrlControllerGetSignedUrl(fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskTaskApi - axios parameter creator
 * @export
 */
export const TaskTaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add comment to task
         * @param {string} id 
         * @param {AddCommentToTaskRequestDto} addCommentToTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToTaskControllerAddCommentToTask: async (id: string, addCommentToTaskRequestDto: AddCommentToTaskRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addCommentToTaskControllerAddCommentToTask', 'id', id)
            // verify required parameter 'addCommentToTaskRequestDto' is not null or undefined
            assertParamExists('addCommentToTaskControllerAddCommentToTask', 'addCommentToTaskRequestDto', addCommentToTaskRequestDto)
            const localVarPath = `/tasks/{id}/comment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCommentToTaskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTaskControllerCompleteTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeTaskControllerCompleteTask', 'id', id)
            const localVarPath = `/tasks/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create task
         * @param {CreateTaskRequestDto} createTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskControllerCreateTask: async (createTaskRequestDto: CreateTaskRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskRequestDto' is not null or undefined
            assertParamExists('createTaskControllerCreateTask', 'createTaskRequestDto', createTaskRequestDto)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many tasks
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTasksControllerGetManyTasks: async (page: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyTasksControllerGetManyTasks', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyTasksControllerGetManyTasks', 'limit', limit)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTaskControllerGetOneTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneTaskControllerGetOneTask', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark task as read
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markTaskAsReadControllerMarkTaskAsRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markTaskAsReadControllerMarkTaskAsRead', 'id', id)
            const localVarPath = `/tasks/{id}/mark-as-read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTaskControllerRemoveTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTaskControllerRemoveTask', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Take task to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeTaskToWorkControllerTakeTaskToWork: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('takeTaskToWorkControllerTakeTaskToWork', 'id', id)
            const localVarPath = `/tasks/{id}/take-to-work`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update task
         * @param {string} id 
         * @param {UpdateTaskRequestDto} updateTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskControllerUpdateTask: async (id: string, updateTaskRequestDto: UpdateTaskRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskControllerUpdateTask', 'id', id)
            // verify required parameter 'updateTaskRequestDto' is not null or undefined
            assertParamExists('updateTaskControllerUpdateTask', 'updateTaskRequestDto', updateTaskRequestDto)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskTaskApi - functional programming interface
 * @export
 */
export const TaskTaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskTaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add comment to task
         * @param {string} id 
         * @param {AddCommentToTaskRequestDto} addCommentToTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentToTaskControllerAddCommentToTask(id: string, addCommentToTaskRequestDto: AddCommentToTaskRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCommentToTaskControllerAddCommentToTask(id, addCommentToTaskRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeTaskControllerCompleteTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeTaskControllerCompleteTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create task
         * @param {CreateTaskRequestDto} createTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskControllerCreateTask(createTaskRequestDto: CreateTaskRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskControllerCreateTask(createTaskRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many tasks
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyTasksControllerGetManyTasks(page: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyTasksDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyTasksControllerGetManyTasks(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneTaskControllerGetOneTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneTaskDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneTaskControllerGetOneTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark task as read
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markTaskAsReadControllerMarkTaskAsRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markTaskAsReadControllerMarkTaskAsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTaskControllerRemoveTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTaskControllerRemoveTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Take task to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeTaskToWorkControllerTakeTaskToWork(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeTaskToWorkControllerTakeTaskToWork(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update task
         * @param {string} id 
         * @param {UpdateTaskRequestDto} updateTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskControllerUpdateTask(id: string, updateTaskRequestDto: UpdateTaskRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskControllerUpdateTask(id, updateTaskRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskTaskApi - factory interface
 * @export
 */
export const TaskTaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskTaskApiFp(configuration)
    return {
        /**
         * 
         * @summary Add comment to task
         * @param {string} id 
         * @param {AddCommentToTaskRequestDto} addCommentToTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentToTaskControllerAddCommentToTask(id: string, addCommentToTaskRequestDto: AddCommentToTaskRequestDto, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.addCommentToTaskControllerAddCommentToTask(id, addCommentToTaskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTaskControllerCompleteTask(id: string, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.completeTaskControllerCompleteTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create task
         * @param {CreateTaskRequestDto} createTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskControllerCreateTask(createTaskRequestDto: CreateTaskRequestDto, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.createTaskControllerCreateTask(createTaskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many tasks
         * @param {number} page 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyTasksControllerGetManyTasks(page: number, limit: number, options?: any): AxiosPromise<GetManyTasksDaoModel> {
            return localVarFp.getManyTasksControllerGetManyTasks(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTaskControllerGetOneTask(id: string, options?: any): AxiosPromise<GetOneTaskDaoModel> {
            return localVarFp.getOneTaskControllerGetOneTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark task as read
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markTaskAsReadControllerMarkTaskAsRead(id: string, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.markTaskAsReadControllerMarkTaskAsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove task
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTaskControllerRemoveTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTaskControllerRemoveTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Take task to work
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeTaskToWorkControllerTakeTaskToWork(id: string, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.takeTaskToWorkControllerTakeTaskToWork(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update task
         * @param {string} id 
         * @param {UpdateTaskRequestDto} updateTaskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskControllerUpdateTask(id: string, updateTaskRequestDto: UpdateTaskRequestDto, options?: any): AxiosPromise<TaskResponseDto> {
            return localVarFp.updateTaskControllerUpdateTask(id, updateTaskRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskTaskApi - object-oriented interface
 * @export
 * @class TaskTaskApi
 * @extends {BaseAPI}
 */
export class TaskTaskApi extends BaseAPI {
    /**
     * 
     * @summary Add comment to task
     * @param {string} id 
     * @param {AddCommentToTaskRequestDto} addCommentToTaskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public addCommentToTaskControllerAddCommentToTask(id: string, addCommentToTaskRequestDto: AddCommentToTaskRequestDto, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).addCommentToTaskControllerAddCommentToTask(id, addCommentToTaskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete task
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public completeTaskControllerCompleteTask(id: string, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).completeTaskControllerCompleteTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create task
     * @param {CreateTaskRequestDto} createTaskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public createTaskControllerCreateTask(createTaskRequestDto: CreateTaskRequestDto, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).createTaskControllerCreateTask(createTaskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many tasks
     * @param {number} page 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public getManyTasksControllerGetManyTasks(page: number, limit: number, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).getManyTasksControllerGetManyTasks(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one task
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public getOneTaskControllerGetOneTask(id: string, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).getOneTaskControllerGetOneTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark task as read
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public markTaskAsReadControllerMarkTaskAsRead(id: string, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).markTaskAsReadControllerMarkTaskAsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove task
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public removeTaskControllerRemoveTask(id: string, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).removeTaskControllerRemoveTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Take task to work
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public takeTaskToWorkControllerTakeTaskToWork(id: string, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).takeTaskToWorkControllerTakeTaskToWork(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update task
     * @param {string} id 
     * @param {UpdateTaskRequestDto} updateTaskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskTaskApi
     */
    public updateTaskControllerUpdateTask(id: string, updateTaskRequestDto: UpdateTaskRequestDto, options?: AxiosRequestConfig) {
        return TaskTaskApiFp(this.configuration).updateTaskControllerUpdateTask(id, updateTaskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TelegramWebhookDevelopersOnlyApi - axios parameter creator
 * @export
 */
export const TelegramWebhookDevelopersOnlyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhookControllerDeleteWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/telegram/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get webhook info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookInfoControllerGetWebhookInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/telegram/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set webhook
         * @param {SetWebhookRequestDto} setWebhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebhookControllerSetWebhook: async (setWebhookRequestDto: SetWebhookRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setWebhookRequestDto' is not null or undefined
            assertParamExists('setWebhookControllerSetWebhook', 'setWebhookRequestDto', setWebhookRequestDto)
            const localVarPath = `/telegram/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWebhookRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Telegram webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramWebhookControllerTelegramWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/telegram/webhook/updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TelegramWebhookDevelopersOnlyApi - functional programming interface
 * @export
 */
export const TelegramWebhookDevelopersOnlyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TelegramWebhookDevelopersOnlyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhookControllerDeleteWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhookControllerDeleteWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get webhook info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookInfoControllerGetWebhookInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookInfoControllerGetWebhookInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set webhook
         * @param {SetWebhookRequestDto} setWebhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWebhookControllerSetWebhook(setWebhookRequestDto: SetWebhookRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWebhookControllerSetWebhook(setWebhookRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Telegram webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async telegramWebhookControllerTelegramWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.telegramWebhookControllerTelegramWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TelegramWebhookDevelopersOnlyApi - factory interface
 * @export
 */
export const TelegramWebhookDevelopersOnlyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TelegramWebhookDevelopersOnlyApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhookControllerDeleteWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebhookControllerDeleteWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get webhook info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookInfoControllerGetWebhookInfo(options?: any): AxiosPromise<string> {
            return localVarFp.getWebhookInfoControllerGetWebhookInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set webhook
         * @param {SetWebhookRequestDto} setWebhookRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWebhookControllerSetWebhook(setWebhookRequestDto: SetWebhookRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.setWebhookControllerSetWebhook(setWebhookRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Telegram webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramWebhookControllerTelegramWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.telegramWebhookControllerTelegramWebhook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TelegramWebhookDevelopersOnlyApi - object-oriented interface
 * @export
 * @class TelegramWebhookDevelopersOnlyApi
 * @extends {BaseAPI}
 */
export class TelegramWebhookDevelopersOnlyApi extends BaseAPI {
    /**
     * 
     * @summary Delete webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelegramWebhookDevelopersOnlyApi
     */
    public deleteWebhookControllerDeleteWebhook(options?: AxiosRequestConfig) {
        return TelegramWebhookDevelopersOnlyApiFp(this.configuration).deleteWebhookControllerDeleteWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get webhook info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelegramWebhookDevelopersOnlyApi
     */
    public getWebhookInfoControllerGetWebhookInfo(options?: AxiosRequestConfig) {
        return TelegramWebhookDevelopersOnlyApiFp(this.configuration).getWebhookInfoControllerGetWebhookInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set webhook
     * @param {SetWebhookRequestDto} setWebhookRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelegramWebhookDevelopersOnlyApi
     */
    public setWebhookControllerSetWebhook(setWebhookRequestDto: SetWebhookRequestDto, options?: AxiosRequestConfig) {
        return TelegramWebhookDevelopersOnlyApiFp(this.configuration).setWebhookControllerSetWebhook(setWebhookRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Telegram webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelegramWebhookDevelopersOnlyApi
     */
    public telegramWebhookControllerTelegramWebhook(options?: AxiosRequestConfig) {
        return TelegramWebhookDevelopersOnlyApiFp(this.configuration).telegramWebhookControllerTelegramWebhook(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseProviderApi - axios parameter creator
 * @export
 */
export const WarehouseProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create provider
         * @param {CreateProviderRequestDto} createProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderControllerCreateProvider: async (createProviderRequestDto: CreateProviderRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderRequestDto' is not null or undefined
            assertParamExists('createProviderControllerCreateProvider', 'createProviderRequestDto', createProviderRequestDto)
            const localVarPath = `/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many providers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyProvidersControllerGetManyProviders: async (page: number, limit: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyProvidersControllerGetManyProviders', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyProvidersControllerGetManyProviders', 'limit', limit)
            const localVarPath = `/provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneProviderControllerGetOneProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneProviderControllerGetOneProvider', 'id', id)
            const localVarPath = `/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProviderControllerRemoveProvider: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeProviderControllerRemoveProvider', 'id', id)
            const localVarPath = `/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update provider
         * @param {string} id 
         * @param {UpdateProviderRequestDto} updateProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderControllerUpdateProvider: async (id: string, updateProviderRequestDto: UpdateProviderRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProviderControllerUpdateProvider', 'id', id)
            // verify required parameter 'updateProviderRequestDto' is not null or undefined
            assertParamExists('updateProviderControllerUpdateProvider', 'updateProviderRequestDto', updateProviderRequestDto)
            const localVarPath = `/provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseProviderApi - functional programming interface
 * @export
 */
export const WarehouseProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create provider
         * @param {CreateProviderRequestDto} createProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderControllerCreateProvider(createProviderRequestDto: CreateProviderRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProviderControllerCreateProvider(createProviderRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many providers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyProvidersControllerGetManyProviders(page: number, limit: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyProvidersDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyProvidersControllerGetManyProviders(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneProviderControllerGetOneProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneProviderDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneProviderControllerGetOneProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProviderControllerRemoveProvider(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProviderControllerRemoveProvider(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update provider
         * @param {string} id 
         * @param {UpdateProviderRequestDto} updateProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderControllerUpdateProvider(id: string, updateProviderRequestDto: UpdateProviderRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderControllerUpdateProvider(id, updateProviderRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseProviderApi - factory interface
 * @export
 */
export const WarehouseProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseProviderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create provider
         * @param {CreateProviderRequestDto} createProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderControllerCreateProvider(createProviderRequestDto: CreateProviderRequestDto, options?: any): AxiosPromise<ProviderResponseDto> {
            return localVarFp.createProviderControllerCreateProvider(createProviderRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many providers
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyProvidersControllerGetManyProviders(page: number, limit: number, search?: string, options?: any): AxiosPromise<GetManyProvidersDaoModel> {
            return localVarFp.getManyProvidersControllerGetManyProviders(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneProviderControllerGetOneProvider(id: string, options?: any): AxiosPromise<GetOneProviderDaoModel> {
            return localVarFp.getOneProviderControllerGetOneProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove provider
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProviderControllerRemoveProvider(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeProviderControllerRemoveProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update provider
         * @param {string} id 
         * @param {UpdateProviderRequestDto} updateProviderRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderControllerUpdateProvider(id: string, updateProviderRequestDto: UpdateProviderRequestDto, options?: any): AxiosPromise<ProviderResponseDto> {
            return localVarFp.updateProviderControllerUpdateProvider(id, updateProviderRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseProviderApi - object-oriented interface
 * @export
 * @class WarehouseProviderApi
 * @extends {BaseAPI}
 */
export class WarehouseProviderApi extends BaseAPI {
    /**
     * 
     * @summary Create provider
     * @param {CreateProviderRequestDto} createProviderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseProviderApi
     */
    public createProviderControllerCreateProvider(createProviderRequestDto: CreateProviderRequestDto, options?: AxiosRequestConfig) {
        return WarehouseProviderApiFp(this.configuration).createProviderControllerCreateProvider(createProviderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many providers
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseProviderApi
     */
    public getManyProvidersControllerGetManyProviders(page: number, limit: number, search?: string, options?: AxiosRequestConfig) {
        return WarehouseProviderApiFp(this.configuration).getManyProvidersControllerGetManyProviders(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one provider
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseProviderApi
     */
    public getOneProviderControllerGetOneProvider(id: string, options?: AxiosRequestConfig) {
        return WarehouseProviderApiFp(this.configuration).getOneProviderControllerGetOneProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove provider
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseProviderApi
     */
    public removeProviderControllerRemoveProvider(id: string, options?: AxiosRequestConfig) {
        return WarehouseProviderApiFp(this.configuration).removeProviderControllerRemoveProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update provider
     * @param {string} id 
     * @param {UpdateProviderRequestDto} updateProviderRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseProviderApi
     */
    public updateProviderControllerUpdateProvider(id: string, updateProviderRequestDto: UpdateProviderRequestDto, options?: AxiosRequestConfig) {
        return WarehouseProviderApiFp(this.configuration).updateProviderControllerUpdateProvider(id, updateProviderRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseVendorApi - axios parameter creator
 * @export
 */
export const WarehouseVendorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create vendor
         * @param {CreateVendorRequestDto} createVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorControllerCreateVendor: async (createVendorRequestDto: CreateVendorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVendorRequestDto' is not null or undefined
            assertParamExists('createVendorControllerCreateVendor', 'createVendorRequestDto', createVendorRequestDto)
            const localVarPath = `/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVendorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many vendors
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyVendorsControllerGetManyVendors: async (page: number, limit: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyVendorsControllerGetManyVendors', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyVendorsControllerGetManyVendors', 'limit', limit)
            const localVarPath = `/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVendorControllerGetOneVendor: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneVendorControllerGetOneVendor', 'id', id)
            const localVarPath = `/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeVendorControllerRemoveVendor: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeVendorControllerRemoveVendor', 'id', id)
            const localVarPath = `/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vendor
         * @param {string} id 
         * @param {UpdateVendorRequestDto} updateVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorControllerUpdateVendor: async (id: string, updateVendorRequestDto: UpdateVendorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVendorControllerUpdateVendor', 'id', id)
            // verify required parameter 'updateVendorRequestDto' is not null or undefined
            assertParamExists('updateVendorControllerUpdateVendor', 'updateVendorRequestDto', updateVendorRequestDto)
            const localVarPath = `/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVendorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseVendorApi - functional programming interface
 * @export
 */
export const WarehouseVendorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseVendorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create vendor
         * @param {CreateVendorRequestDto} createVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVendorControllerCreateVendor(createVendorRequestDto: CreateVendorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVendorControllerCreateVendor(createVendorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many vendors
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyVendorsControllerGetManyVendors(page: number, limit: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyVendorsDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyVendorsControllerGetManyVendors(page, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneVendorControllerGetOneVendor(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneVendorDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneVendorControllerGetOneVendor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeVendorControllerRemoveVendor(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeVendorControllerRemoveVendor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vendor
         * @param {string} id 
         * @param {UpdateVendorRequestDto} updateVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorControllerUpdateVendor(id: string, updateVendorRequestDto: UpdateVendorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorControllerUpdateVendor(id, updateVendorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseVendorApi - factory interface
 * @export
 */
export const WarehouseVendorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseVendorApiFp(configuration)
    return {
        /**
         * 
         * @summary Create vendor
         * @param {CreateVendorRequestDto} createVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorControllerCreateVendor(createVendorRequestDto: CreateVendorRequestDto, options?: any): AxiosPromise<VendorResponseDto> {
            return localVarFp.createVendorControllerCreateVendor(createVendorRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many vendors
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyVendorsControllerGetManyVendors(page: number, limit: number, search?: string, options?: any): AxiosPromise<GetManyVendorsDaoModel> {
            return localVarFp.getManyVendorsControllerGetManyVendors(page, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVendorControllerGetOneVendor(id: string, options?: any): AxiosPromise<GetOneVendorDaoModel> {
            return localVarFp.getOneVendorControllerGetOneVendor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeVendorControllerRemoveVendor(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeVendorControllerRemoveVendor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vendor
         * @param {string} id 
         * @param {UpdateVendorRequestDto} updateVendorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorControllerUpdateVendor(id: string, updateVendorRequestDto: UpdateVendorRequestDto, options?: any): AxiosPromise<VendorResponseDto> {
            return localVarFp.updateVendorControllerUpdateVendor(id, updateVendorRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseVendorApi - object-oriented interface
 * @export
 * @class WarehouseVendorApi
 * @extends {BaseAPI}
 */
export class WarehouseVendorApi extends BaseAPI {
    /**
     * 
     * @summary Create vendor
     * @param {CreateVendorRequestDto} createVendorRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseVendorApi
     */
    public createVendorControllerCreateVendor(createVendorRequestDto: CreateVendorRequestDto, options?: AxiosRequestConfig) {
        return WarehouseVendorApiFp(this.configuration).createVendorControllerCreateVendor(createVendorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many vendors
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseVendorApi
     */
    public getManyVendorsControllerGetManyVendors(page: number, limit: number, search?: string, options?: AxiosRequestConfig) {
        return WarehouseVendorApiFp(this.configuration).getManyVendorsControllerGetManyVendors(page, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one vendor
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseVendorApi
     */
    public getOneVendorControllerGetOneVendor(id: string, options?: AxiosRequestConfig) {
        return WarehouseVendorApiFp(this.configuration).getOneVendorControllerGetOneVendor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove vendor
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseVendorApi
     */
    public removeVendorControllerRemoveVendor(id: string, options?: AxiosRequestConfig) {
        return WarehouseVendorApiFp(this.configuration).removeVendorControllerRemoveVendor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vendor
     * @param {string} id 
     * @param {UpdateVendorRequestDto} updateVendorRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseVendorApi
     */
    public updateVendorControllerUpdateVendor(id: string, updateVendorRequestDto: UpdateVendorRequestDto, options?: AxiosRequestConfig) {
        return WarehouseVendorApiFp(this.configuration).updateVendorControllerUpdateVendor(id, updateVendorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseWarehouseItemApi - axios parameter creator
 * @export
 */
export const WarehouseWarehouseItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWarehouseItemControllerArchiveWarehouseItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveWarehouseItemControllerArchiveWarehouseItem', 'id', id)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create warehouse item
         * @param {CreateWarehouseItemRequestDto} createWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehouseItemControllerCreateWarehouseItem: async (createWarehouseItemRequestDto: CreateWarehouseItemRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWarehouseItemRequestDto' is not null or undefined
            assertParamExists('createWarehouseItemControllerCreateWarehouseItem', 'createWarehouseItemRequestDto', createWarehouseItemRequestDto)
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWarehouseItemRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many warehouse items
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isExactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyWarehouseItemsControllerGetManyWarehouseItems: async (page: number, limit: number, search?: string, isArchived?: boolean, isExactMatch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getManyWarehouseItemsControllerGetManyWarehouseItems', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getManyWarehouseItemsControllerGetManyWarehouseItems', 'limit', limit)
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }

            if (isExactMatch !== undefined) {
                localVarQueryParameter['isExactMatch'] = isExactMatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneWarehouseItemControllerGetOneWarehouseItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneWarehouseItemControllerGetOneWarehouseItem', 'id', id)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update warehouse item
         * @param {string} id 
         * @param {UpdateWarehouseItemRequestDto} updateWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseItemControllerUpdateWarehouseItem: async (id: string, updateWarehouseItemRequestDto: UpdateWarehouseItemRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWarehouseItemControllerUpdateWarehouseItem', 'id', id)
            // verify required parameter 'updateWarehouseItemRequestDto' is not null or undefined
            assertParamExists('updateWarehouseItemControllerUpdateWarehouseItem', 'updateWarehouseItemRequestDto', updateWarehouseItemRequestDto)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWarehouseItemRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseWarehouseItemApi - functional programming interface
 * @export
 */
export const WarehouseWarehouseItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseWarehouseItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveWarehouseItemControllerArchiveWarehouseItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveWarehouseItemControllerArchiveWarehouseItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create warehouse item
         * @param {CreateWarehouseItemRequestDto} createWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto: CreateWarehouseItemRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many warehouse items
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isExactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyWarehouseItemsControllerGetManyWarehouseItems(page: number, limit: number, search?: string, isArchived?: boolean, isExactMatch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyWarehouseItemsDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyWarehouseItemsControllerGetManyWarehouseItems(page, limit, search, isArchived, isExactMatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneWarehouseItemControllerGetOneWarehouseItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOneWarehouseItemDaoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneWarehouseItemControllerGetOneWarehouseItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update warehouse item
         * @param {string} id 
         * @param {UpdateWarehouseItemRequestDto} updateWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarehouseItemControllerUpdateWarehouseItem(id: string, updateWarehouseItemRequestDto: UpdateWarehouseItemRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarehouseItemControllerUpdateWarehouseItem(id, updateWarehouseItemRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseWarehouseItemApi - factory interface
 * @export
 */
export const WarehouseWarehouseItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseWarehouseItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveWarehouseItemControllerArchiveWarehouseItem(id: string, options?: any): AxiosPromise<WarehouseItemResponseDto> {
            return localVarFp.archiveWarehouseItemControllerArchiveWarehouseItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create warehouse item
         * @param {CreateWarehouseItemRequestDto} createWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto: CreateWarehouseItemRequestDto, options?: any): AxiosPromise<WarehouseItemResponseDto> {
            return localVarFp.createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many warehouse items
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isExactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyWarehouseItemsControllerGetManyWarehouseItems(page: number, limit: number, search?: string, isArchived?: boolean, isExactMatch?: boolean, options?: any): AxiosPromise<GetManyWarehouseItemsDaoModel> {
            return localVarFp.getManyWarehouseItemsControllerGetManyWarehouseItems(page, limit, search, isArchived, isExactMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one warehouse item
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneWarehouseItemControllerGetOneWarehouseItem(id: string, options?: any): AxiosPromise<GetOneWarehouseItemDaoModel> {
            return localVarFp.getOneWarehouseItemControllerGetOneWarehouseItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update warehouse item
         * @param {string} id 
         * @param {UpdateWarehouseItemRequestDto} updateWarehouseItemRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseItemControllerUpdateWarehouseItem(id: string, updateWarehouseItemRequestDto: UpdateWarehouseItemRequestDto, options?: any): AxiosPromise<WarehouseItemResponseDto> {
            return localVarFp.updateWarehouseItemControllerUpdateWarehouseItem(id, updateWarehouseItemRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseWarehouseItemApi - object-oriented interface
 * @export
 * @class WarehouseWarehouseItemApi
 * @extends {BaseAPI}
 */
export class WarehouseWarehouseItemApi extends BaseAPI {
    /**
     * 
     * @summary Archive warehouse item
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseWarehouseItemApi
     */
    public archiveWarehouseItemControllerArchiveWarehouseItem(id: string, options?: AxiosRequestConfig) {
        return WarehouseWarehouseItemApiFp(this.configuration).archiveWarehouseItemControllerArchiveWarehouseItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create warehouse item
     * @param {CreateWarehouseItemRequestDto} createWarehouseItemRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseWarehouseItemApi
     */
    public createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto: CreateWarehouseItemRequestDto, options?: AxiosRequestConfig) {
        return WarehouseWarehouseItemApiFp(this.configuration).createWarehouseItemControllerCreateWarehouseItem(createWarehouseItemRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many warehouse items
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [search] 
     * @param {boolean} [isArchived] 
     * @param {boolean} [isExactMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseWarehouseItemApi
     */
    public getManyWarehouseItemsControllerGetManyWarehouseItems(page: number, limit: number, search?: string, isArchived?: boolean, isExactMatch?: boolean, options?: AxiosRequestConfig) {
        return WarehouseWarehouseItemApiFp(this.configuration).getManyWarehouseItemsControllerGetManyWarehouseItems(page, limit, search, isArchived, isExactMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one warehouse item
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseWarehouseItemApi
     */
    public getOneWarehouseItemControllerGetOneWarehouseItem(id: string, options?: AxiosRequestConfig) {
        return WarehouseWarehouseItemApiFp(this.configuration).getOneWarehouseItemControllerGetOneWarehouseItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update warehouse item
     * @param {string} id 
     * @param {UpdateWarehouseItemRequestDto} updateWarehouseItemRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseWarehouseItemApi
     */
    public updateWarehouseItemControllerUpdateWarehouseItem(id: string, updateWarehouseItemRequestDto: UpdateWarehouseItemRequestDto, options?: AxiosRequestConfig) {
        return WarehouseWarehouseItemApiFp(this.configuration).updateWarehouseItemControllerUpdateWarehouseItem(id, updateWarehouseItemRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


