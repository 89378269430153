export const RoutePaths = {
  main: '/',
  plug: '/plug',
  login: '/login',
  adminLayout: '/admin',
  profile: '/admin/profile',
  orders: '/admin/orders',
  order: '/admin/orders/:id',
  editOrder: '/admin/orders/:id/edit',
  orderDiagnosed: '/admin/orders/:id/diagnosed',
  newOrder: '/admin/orders/new',
  getOrderRoute: (id: string) => `/admin/orders/${id}`,
  getEditOrderRoute: (id: string) => `/admin/orders/${id}/edit`,
  getOrderDiagnosedRoute: (id: string) => `/admin/orders/${id}/diagnosed`,
  tasks: '/admin/tasks',
  task: '/admin/tasks/:id',
  editTask: '/admin/tasks/:id/edit',
  newTask: '/admin/tasks/new',
  getTaskRoute: (id: string) => `/admin/tasks/${id}`,
  getEditTaskRoute: (id: string) => `/admin/tasks/${id}/edit`,
  clients: '/admin/clients',
  client: '/admin/clients/:id',
  newClient: '/admin/clients/new',
  editClient: '/admin/clients/:id/edit',
  getClientRoute: (id: string) => `/admin/clients/${id}`,
  getEditClientRoute: (id: string) => `/admin/clients/${id}/edit`,
  staff: '/admin/staff',
  newStaff: '/admin/staff/new',
  oneStaff: '/admin/staff/:id',
  editStaff: '/admin/staff/:id/edit',
  getOneStaffRoute: (id: string) => `/admin/staff/${id}`,
  getEditStaffRoute: (id: string) => `/admin/staff/${id}/edit`,
  deadlines: '/admin/deadlines',
  providers: '/admin/providers',
  provider: '/admin/providers/:id',
  newProvider: '/admin/providers/new',
  editProvider: '/admin/providers/:id/edit',
  getProviderRoute: (id: string) => `/admin/providers/${id}`,
  getEditProviderRoute: (id: string) => `/admin/providers/${id}/edit`,
  vendors: '/admin/vendors',
  vendor: '/admin/vendors/:id',
  newVendor: '/admin/vendors/new',
  editVendor: '/admin/vendors/:id/edit',
  getVendorRoute: (id: string) => `/admin/vendors/${id}`,
  getEditVendorRoute: (id: string) => `/admin/vendors/${id}/edit`,
  works: '/admin/works',
  work: '/admin/works/:id',
  newWork: '/admin/works/new',
  editWork: '/admin/works/:id/edit',
  getWorkRoute: (id: string) => `/admin/works/${id}`,
  getEditWorkRoute: (id: string) => `/admin/works/${id}/edit`,
  warehouseItems: '/admin/warehouse-items',
  warehouseItem: '/admin/warehouse-items/:id',
  newWarehouseItem: '/admin/warehouse-items/new',
  editWarehouseItem: '/admin/warehouse-items/:id/edit',
  getWarehouseItemRoute: (id: string) => `/admin/warehouse-items/${id}`,
  getEditWarehouseItemRoute: (id: string) => `/admin/warehouse-items/${id}/edit`,
};
